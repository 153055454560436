@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

#notification-wrapper, #notification-wrapper div {
  z-index: 99999999;
}

.DateInput_input.DateInput_input_1.DateInput_input__small.DateInput_input__small_2 {
  font-size: 14px;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.navbar-dropdown::before {
  display: none !important;
}

#notification-wrapper {
  position: fixed;
}

.multi-items {
  font-weight: bold !important;
  color: red !important;
}

.wrapped {
  display: flex;
  flex-direction: row;
  width: 90vw;
  max-width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  -webkit-overflow-scrolling: touch !important;
  padding-bottom: 2px;
}

.wrapped::-webkit-scrollbar { 
  display: none;
}

.apexcharts-tooltip-y-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}