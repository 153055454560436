.layout {
    display: grid;
    align-items: center;
    grid-gap: 30px;
    grid-template:
        "cropper preview"
        "cropper platform-preview";
    grid-template-columns: 2fr min-content;
}

.cropper {
    grid-area: cropper;
    justify-self: center;
}

.preview {
    grid-area: preview;
}

.img-preview {
    overflow: hidden;
    width: 300px;
    height: 300px;
    max-width: 300px;
    max-height: 300px;
    border: 1px solid grey;
}

.platform-preview {
    grid-area: platform-preview;
    justify-self: start;
}

.platform-preview img {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}
